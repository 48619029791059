export const environment = {
  firebase: {
    projectId: 'market-monitor-prod',
    appId: '1:185409661855:web:0c3e2fbdd4f05709f6ebaa',
    storageBucket: 'market-monitor-prod.appspot.com',
    apiKey: 'AIzaSyAv5SuzwrwGv0Z5RzFYHf5bdkjb60ucOAk',
    authDomain: 'market-monitor-prod.firebaseapp.com',
    messagingSenderId: '185409661855',
    measurementId: 'G-82FD6D7MMR',
  },

  production: true,
  version: '1.8.1',
};
